<template>
  <v-sheet
    class="calendar-module main-listing-page px-3"
    style="height: calc(100vh - 88px); background: #f5f5f5"
  >
    <FullDialog
      :dialog="calendarDialog"
      v-on:close="calendarDialog = false"
      :back-route="{ name: 'admin.dashboard' }"
    >
      <template v-slot:title>
        <v-toolbar height="34" flat class="bg-transparent py-0">
          <v-btn depressed tile color="cyan white--text" @click="setToday">
            Today
          </v-btn>
          <v-btn
            fab
            outlined
            height="28"
            width="28"
            class="ml-3"
            color="grey darken-2"
            @click="prev"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn
            fab
            outlined
            height="28"
            width="28"
            class="ml-3"
            color="grey darken-2"
            @click="next"
          >
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar" class="ml-3">
            <span style="font-size: 16px" class="fw-600">
              {{ $refs.calendar.title }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex class="d-flex justify-content-end align-items-center">
            <div
              class="mx-5 font-level-3-bold d-inline-block primary--text text--darken-4 fw-600"
              style=""
            >
              Filters
            </div>
            <v-flex class="mr-2" style="max-width: 15rem">
              <DatePicker
                outlined
                depressed
                hide-details
                placeholder="Select Date"
                v-on:click:clear="getCalendar()"
                class-name="mt-0"
                clearable
                v-on:change="getCalendar($event)"
              >
              </DatePicker>
            </v-flex>

            <v-flex class="mr-2" style="max-width: 19rem" v-if="false">
              <v-autocomplete
                :items="filterList"
                v-model="filter"
                hide-details
                clearable
                v-on:click:clear="getCalendar()"
                flat
                solo
                dense
                placeholder="Type"
                color="cyan"
                item-color="cyan"
                v-on:change="getCalendar()"
                class="pt-0 mt-0"
              >
                <template v-slot:selection="{ item }">
                  <v-list-item-action class="ma-0">
                    <v-chip
                      small
                      style="height: 20px !important; width: 20px; padding: 0"
                      :color="item.color"
                      class="d-flex justify-center align-center"
                    ></v-chip>
                  </v-list-item-action>
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="text-capitalize font-weight-500 font-size-16"
                    >
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-action class="mr-0">
                    <v-chip
                      small
                      style="height: 20px !important; width: 20px; padding: 0"
                      :color="item.color"
                      class="d-flex justify-center align-center"
                    ></v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize font-weight-500 font-size-16"
                      >{{ item.text }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex class="mr-2" style="max-width: 20rem">
              <v-autocomplete
                :items="typeList"
                v-model="job_type"
                hide-details
                clearable
                flat
                v-on:click:clear="
                  job_type = null;
                  getCalendar();
                "
                solo
                dense
                placeholder="Job Type"
                v-on:change="getCalendar()"
                color="cyan"
                item-color="cyan"
                class="pt-0 mt-0"
              >
                <template v-slot:selection="{ item }">
                  <v-list-item-action class="ma-0">
                    <v-chip
                      small
                      style="height: 20px !important; width: 20px; padding: 0"
                      :color="item.color"
                      class="d-flex justify-center align-center"
                    ></v-chip>
                  </v-list-item-action>
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="text-capitalize font-weight-500 font-size-16"
                    >
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-action class="mr-0">
                    <v-chip
                      small
                      style="height: 20px !important; width: 20px; padding: 0"
                      :color="item.color"
                      class="d-flex justify-center align-center"
                    ></v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize font-weight-500 font-size-16"
                      >{{ item.text }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex class="mr-2">
              <v-autocomplete
                :items="customerList"
                v-model="customer"
                hide-details
                clearable
                v-on:click:clear="
                  customer = null;
                  getCalendar();
                "
                flat
                v-on:change="getCalendar()"
                solo
                dense
                placeholder="Select Customer"
                color="blue darken-4"
                item-color="blue darken-4"
                item-text="display_name"
                item-value="id"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex class="mr-2">
              <v-autocomplete
                :items="engineerList"
                v-model="engineer"
                hide-details
                clearable
                v-on:click:clear="
                  engineer = null;
                  getCalendar();
                "
                flat
                v-on:change="getCalendar()"
                solo
                dense
                placeholder="Select Technician"
                color="blue darken-4"
                item-color="blue darken-4"
                item-text="display_name"
                item-value="id"
                style="max-width: 230px"
              >
              </v-autocomplete>
            </v-flex>
          </v-flex>
          <v-menu v-if="false" left tile deppressed>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                tile
                outlined
                color="blue darken-4"
                class="mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ calendarFilterType }}</span>
                <v-icon right style="font-size: 24px"> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list class="">
              <v-list-item
                class="border-bottom"
                @click="calendarFilterType = 'Assigned Visit'"
              >
                <v-list-item-title class="fw-600"
                  >Assigned Visit</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="calendarFilterType = 'UnAssigned Visit'">
                <v-list-item-title class="fw-600"
                  >UnAssigned Visit</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu left tile deppressed>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                tile
                color="cyan white--text"
                class="white--text"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            depressed
            tile
            color="cyan white--text"
            class="white--text ml-3"
            :to="{ name: 'admin.calendar-resource' }"
          >
            Resource Calendar
          </v-btn>
          <!--   <v-btn
            fab
            outlined
            height="28"
            width="28"
            color="grey darken-4"
            class="white--text ml-3"
            @click="toggleVisit = !toggleVisit"
          >
            <v-icon size="20">{{
              toggleVisit
                ? "mdi-format-indent-increase"
                : "mdi-format-indent-decrease"
            }}</v-icon>
          </v-btn> -->
        </v-toolbar>
      </template>

      <template v-slot:body>
        <div class="calendar-module">
          <div class="calendar-wrapper">
            <div class="calendar-area">
              <div class="calendar pl-2" style="height: calc(100vh - 140px)">
                <!--  <pre>{{ events }}</pre> -->
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  style="width: 100%"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="openSingledata"
                  @click:more="showAllEvent"
                  @click:date="createGoogleEvent"
                  @change="updateRange"
                  :weekdays="cWeekdays"
                >
                  <template v-slot:day="day">
                    <div :class="`d-none day-${day.weekday}`"></div>
                  </template>
                  <template v-slot:event="{ event }">
                    <template v-if="event?.is_event">
                      <div>
                        <v-menu
                          v-model="googleEventMenu[event?.id]"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          offset-x
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              :color="event.color"
                              dark
                              small
                              v-bind="attrs"
                              v-on="on"
                              style="min-width: 100%"
                            >
                              {{ event.name }}
                            </v-chip>
                          </template>

                          <v-card>
                            <div
                              v-html="event?.details"
                              class="px-4 py-4"
                            ></div>
                          </v-card>
                        </v-menu>
                      </div>
                    </template>
                    <template v-else-if="event.type != 'visit'">
                      <!--  <pre>{{ event }}</pre> -->
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                            style="width: 97%"
                            :color="event.color"
                            class="ml-1 mb-2"
                            label
                            outlined
                          >
                            <span
                              class="font-size-10 font-weight-500 text-capitalize"
                            >
                              {{ getEvents(event) }}
                            </span>
                          </v-chip>
                        </template>
                        <span> {{ event.status_text }}</span>
                      </v-tooltip>
                      <!-- <span style="color:black"> 
                     
                  </span> -->
                    </template>
                    <div v-else class="d-flex">
                      <div>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              small
                              v-bind="attrs"
                              v-on="on"
                              :color="event.priority_color"
                              class="ml-1 text-white mt-n1"
                              label
                              style="width: 10px !important"
                            >
                            </v-chip>
                          </template>
                          <span>Priority</span>
                        </v-tooltip>
                      </div>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                            style="width: 97%"
                            :color="event.color"
                            class="ml-1 text-white"
                            label
                          >
                            <span>
                              <div></div>
                            </span>
                            <span
                              class="font-size-10 font-weight-500 text-capitalize"
                            >
                              {{ getEvents(event) }}
                            </span>
                          </v-chip>
                        </template>
                        <span> {{ event.status_text }}</span>
                      </v-tooltip>
                    </div>

                    <!-- <v-chip
               
                small
                style="width: 97%;"
                :color="event.color"
                label
                class="ml-1 text-white"
              >
                <span class="font-size-16 font-weight-500 text-capitalize">
                  {{ event.name }}
                </span>
              </v-chip> -->
                  </template>
                </v-calendar>
                <div class="legends d-flex flex-wrap align-center my-2">
                  <div
                    class="px-2 mr-3 py-1 d-flex align-center border rounded"
                    style="border-color: cyan"
                  >
                    <v-icon size="15" color="cyan" class="mr-1"
                      >mdi-circle</v-icon
                    >
                    <span class="text-black font-weight-700">Visit :</span>
                  </div>

                  <div
                    class="px-2 mr-3 py-1 d-flex align-center border rounded"
                    v-for="(row, index) in statusList"
                    :key="index"
                    :style="{
                      borderColor: row.color,
                      backgroundColor:
                        selectedStatus === row
                          ? lightenBackgroundColor(index)
                          : '',
                      borderWidth: selectedStatus === row ? '2px' : '1px',
                    }"
                    @click="handleStatusClick(row)"
                  >
                    <v-icon size="15" :color="row.color" class="mr-1"
                      >mdi-circle</v-icon
                    >
                    <span class="text-black">{{ row.text }}</span>
                  </div>
                </div>
                <!--          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-y
            top
            left
            min-width="auto"
          >
            <v-card
              color=""
              min-width="300px"
              max-width="350px"
              elevation="0"
              class="all-event-wrapper"
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
                height="40px"
                class=""
                flat
              >
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
              
                <v-icon
                  class="cursor-pointer ml-4"
                  size="18"
                  @click="selectedOpen = false"
                  >mdi-close</v-icon
                >
              </v-toolbar>
              <div class="pa-3">
                <v-card
                  min-width="245px"
                  max-width="300px"
                  flat
                  class="pa-1"
                  color="grey lighten-4"
                >
                 
                </v-card>
              </div>
            </v-card>
          </v-menu> -->
                <v-menu
                  v-model="moreOpen"
                  :close-on-content-click="false"
                  :activator="moreElement"
                  offset-y
                  top
                  left
                >
                  <v-card
                    class="grey pa-2 lighten-4"
                    min-width="350px"
                    max-width="350px"
                    style="height: 600px"
                  >
                    <template v-for="(row, index) in moreData">
                      <v-card-text
                        :class="`${row.color} white--text mb-1 pa-1`"
                        :key="index"
                        v-on:click="openSingledataDoublePopup(row)"
                      >
                        {{ row.name }} {{ formatDate(row.start) }}
                      </v-card-text>
                    </template>
                    <!-- <v-card-text class="blue white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text>
              <v-card-text class="red white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text>
              <v-card-text class="orange white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text>
              <v-card-text class="blue white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text> -->
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div class="sidebar white" v-if="toggleVisit">
              <div class="position-sticky grey lighten-2">
                <div class="d-flex py-1">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <h5
                        class="mt-2 py-0 pl-2 cursor-pointer"
                        style="border-color: #a3a3a3 !important"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ filterType }}
                        <v-icon size="28">mdi-menu-down</v-icon>
                      </h5>
                    </template>
                    <v-list>
                      <v-list-item-group color="primary">
                        <v-list-item link @click="filterType = 'All visit'">
                          <v-list-item-title
                            class="fw-600 d-flex"
                            v-on:click="getVisitList('', 'all')"
                          >
                            <span
                              class="white--text mr-2 rounded-circle blue d-flex justify-center align-center"
                              style="height: 22px; width: 22px"
                            ></span>
                            All Visit</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          link
                          @click="filterType = 'Assigned Visit'"
                        >
                          <v-list-item-title
                            class="fw-600 d-flex"
                            v-on:click="getVisitList('', 'assigned')"
                          >
                            <span
                              class="white--text mr-2 rounded-circle green d-flex justify-center align-center"
                              style="height: 22px; width: 22px"
                            ></span>
                            Assigned Visit</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          link
                          @click="filterType = 'UnAssigned Visit'"
                        >
                          <v-list-item-title
                            class="fw-600 d-flex"
                            v-on:click="getVisitList('', 'ussigned')"
                          >
                            <span
                              class="white--text mr-2 rounded-circle orange d-flex justify-center align-center"
                              style="height: 22px; width: 22px"
                            ></span>
                            UnAssigned Visit</v-list-item-title
                          >
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-spacer></v-spacer>
                  <v-btn
                    fab
                    icon
                    small
                    @click="showCalendarFilter = !showCalendarFilter"
                  >
                    <v-icon>mdi-calendar-check-outline</v-icon>
                  </v-btn>
                  <!--  <v-btn fab icon small min-width="30" width="30">
              <v-icon>mdi-filter</v-icon>
            </v-btn> -->
                </div>
                <div v-if="showCalendarFilter" class="mb-2">
                  <!--  with color <v-date-picker
                    full-width
                    no-title
                    color="green lighten-1"
                    v-model="datePicker"
                    :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
                    :events="functionEvents"
                    v-on:change="getVisitList(datePicker)"
                  ></v-date-picker> -->
                  <v-date-picker
                    full-width
                    no-title
                    color="green lighten-1"
                    v-model="datePicker"
                    v-on:change="getVisitList(datePicker)"
                  ></v-date-picker>
                </div>
                <!--  <div class="key-search mb-2">
            <v-text-field
              class="px-0"
              dense
              filled
              solo
              @keydown.enter="getVisitList"
              v-model="search"
              flat
              placeholder="Search Visit"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div> -->
              </div>
              <div class="event-list px-3">
                <v-list
                  v-if="visitArr && visitArr.length"
                  class="py-0 grey lighten-4"
                >
                  <v-list-item-group
                    v-model="selected"
                    active-class="blue lighten-4"
                  >
                    <template v-for="(item, index) in visitArr">
                      <v-list-item
                        :key="index"
                        class="mb-2 white px-2 border-red"
                        :style="`border-left: ${getPriorityColor(
                          item.ticket_calendar.priority_text
                        )}; box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.1); background-color: ${getBgColor(
                          item.status
                        )}`"
                      >
                        <!--  :style="`border:1px solid ${statusObject ? statusObject.color : ''}`" -->
                        <template v-slot:default="{ active }">
                          <v-list-item-content
                            v-on:click="getCalendar('', item.id)"
                          >
                            <v-list-item-title
                              :class="`blue--text mb-1`"
                              class="fw-700 d-flex align-center"
                            >
                              #{{ item.barcode }}

                              <v-spacer></v-spacer>
                              <span
                                :class="`${getColor(item.status)}--text fw-500`"
                              >
                                <span class="d-block">
                                  {{ formatDate(item.started_at) }}
                                  {{ formatDateTimeStart(item.started_at) }}
                                  <!-- -
                              {{ formatetimedata(item.finished_at) }} -->
                                </span>

                                <!-- {{getPriorityColor(item.priority_text)}} -->
                              </span>
                            </v-list-item-title>
                            <v-list-item-title
                              style="font-size: 16px"
                              class="fw-600"
                              :class="`${
                                active ? getColor(item.status) : ''
                              }--text mb-1`"
                              v-text="item.Title"
                            ></v-list-item-title>
                            <!--   <template  v-if="item.ticket_calendar.type == 2">
                                <div>
                                  <v-chip
                                    small
                                    class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10"
                                    color="chip-custom-blue"
                                    label
                                    outlined
                                  >
                                  <span style="color: red">{{
                                            item.recurring_count
                                          }}</span>
                                          / <span style="color: green">{{ item.ticket_calendar.visit_count }}</span>
                                </div>
                               </template> -->
                            <v-list-item-subtitle
                              class="text--primary fw-500"
                              v-text="item.customer.display_name"
                            ></v-list-item-subtitle>

                            <template v-if="item.ticket_calendar.type == 1">
                              One Off Job
                            </template>
                            <template v-if="item.ticket_calendar.type == 2">
                              Recurring Job
                            </template>
                          </v-list-item-content>

                          <v-list-item-action>
                            <!--   <v-icon
                        size="18"
                        class=""
                        :color="`${getPriorityColor(item.ticket_calendar.priority_text)}`"
                      >
                        mdi-circle
                      </v-icon> -->
                            <v-icon
                              v-if="item.follow_up > 0"
                              color="red"
                              size="18"
                              class="my-1"
                            >
                              mdi-flag</v-icon
                            >
                            <v-icon v-else size="18" class="my-1">
                              mdi-flag</v-icon
                            >
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
                <div v-else>
                  <div class="no-date text-center pa-5 mt-5">
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    <div class="text-h6 orange--text text--lighten-2 pt-3">
                      Uhh... There are no visits at the moment.
                    </div>
                  </div>
                </div>
                <v-menu
                  v-model="eventPopover"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :position-x="positionX"
                  :position-y="positionY"
                  absolute
                  offset-y
                  tile
                  elevation="0"
                  min-width="350"
                >
                  <v-card
                    color=""
                    min-width="300px"
                    max-width="350px"
                    elevation="0"
                    class="all-event-wrapper"
                  >
                    <v-toolbar color="green" dark height="40px" class="">
                      <v-toolbar-title>Lorem, ipsum dolor.</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-icon
                        class="cursor-pointer"
                        size="18"
                        v-on:click="openSingledata($event)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        class="cursor-pointer ml-4"
                        size="18"
                        @click="eventPopover = false"
                        >mdi-close</v-icon
                      >
                    </v-toolbar>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
      </template>
    </FullDialog>
    <CreateEvent
      v-if="false"
      :dialog="viewVisitDialog"
      v-on:close="viewVisitDialog = false"
    ></CreateEvent>

    <SingleVisitDetail
      v-if="viewVisitDialog"
      :visit-dialog="viewVisitDialog"
      :visit="visitId"
      type="simple"
      v-on:close="viewVisitDialog = false"
    ></SingleVisitDetail>
    <SingleDeliveryDetail
      v-if="viewDeliveryDialog"
      :visit-dialog="viewDeliveryDialog"
      :visit="deliveryId"
      v-on:close="viewDeliveryDialog = false"
    ></SingleDeliveryDetail>
    <SingleQuotationDetail
      v-if="viewQuotationDialog"
      :visit-dialog="viewQuotationDialog"
      :visit="quotationId"
      v-on:close="viewQuotationDialog = false"
    ></SingleQuotationDetail>
    <SingleLeaveDetail
      v-if="viewLeaveDialog"
      :visit-dialog="viewLeaveDialog"
      :visit="leaveId"
      v-on:close="viewLeaveDialog = false"
    ></SingleLeaveDetail>
    <SingleContractDetail
      v-if="viewContractDialog"
      :visit-dialog="viewContractDialog"
      :visit="contractId"
      v-on:close="viewContractDialog = false"
    ></SingleContractDetail>
    <SingleProjectDetail
      v-if="viewProjectDialog"
      :visit-dialog="viewProjectDialog"
      :visit="projectId"
      v-on:close="viewProjectDialog = false"
    ></SingleProjectDetail>
    <Dialog :dialog="googleEventDialog" :dialog-width="dialogWidth">
      <template v-slot:title> Create Event </template>
      <template v-slot:body>
        <v-form ref="googleEventForm" v-model="formValid" lazy-validation>
          <v-text-field
            v-model="googleEvent.title"
            label="Title"
            placeholder="Enter event title"
            dense
            filled
            solo
            flat
            color="cyan"
            :disabled="googleEventLoading"
            :loading="googleEventLoading"
            :rules="[googleEventRules.required, googleEventRules.max255]"
          ></v-text-field>

          <v-text-field
            v-model="googleEvent.location"
            label="Location"
            placeholder="Enter event location"
            dense
            filled
            solo
            flat
            color="cyan"
            :disabled="googleEventLoading"
            :loading="googleEventLoading"
            :rules="[googleEventRules.max255]"
          ></v-text-field>

          <v-textarea
            v-model="googleEvent.description"
            label="Description"
            placeholder="Enter event description"
            dense
            filled
            solo
            flat
            color="cyan"
            :disabled="googleEventLoading"
            :loading="googleEventLoading"
            :rules="[googleEventRules.max1000]"
          ></v-textarea>

          <v-text-field
            v-model="googleEvent.start_time"
            label="Start Time"
            type="datetime-local"
            dense
            filled
            solo
            flat
            color="cyan"
            :disabled="googleEventLoading"
            :loading="googleEventLoading"
            :rules="[googleEventRules.required]"
          ></v-text-field>

          <v-text-field
            v-model="googleEvent.end_time"
            label="End Time"
            type="datetime-local"
            dense
            filled
            solo
            flat
            color="cyan"
            :disabled="googleEventLoading"
            :loading="googleEventLoading"
            :rules="[
              googleEventRules.required,
              () =>
                googleEvent.end_time > googleEvent.start_time ||
                'End time must be after start time',
            ]"
          ></v-text-field>

          <v-combobox
            v-if="false"
            v-model="googleEvent.attendees"
            label="Attendees (Emails)"
            multiple
            chips
            placeholder="Add email addresses"
            dense
            filled
            solo
            flat
            color="cyan"
            :disabled="googleEventLoading"
            :loading="googleEventLoading"
            :rules="[googleEventRules.emailList]"
          ></v-combobox>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="googleEventLoading"
          @click="googleEventDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          :disabled="googleEventLoading"
          :loading="googleEventLoading"
          @click="handleGoogleEvent"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Create
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<style>
.v-calendar-weekly__day:hover {
  background-color: #d7e0e1 !important;
}
</style>
<script>
import {
  /* GET, */ QUERY,
  POST,
  GET,
} from "@/core/services/store/request.module";
import CreateEvent from "@/view/pages/calendar/CreateEvent";
import DatePicker from "@/view/components/DatePicker";
import moment from "moment-timezone";
import SingleQuotationDetail from "@/view/pages/job/partials/Single-Quotation-Detail.vue";
import SingleContractDetail from "@/view/pages/job/partials/Single-Contract-Detail.vue";
import SingleProjectDetail from "@/view/pages/job/partials/Single-Project-Detail.vue";
import SingleLeaveDetail from "@/view/pages/job/partials/Single-Leave-Detail.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import SingleDeliveryDetail from "@/view/pages/delivery-job/partials/Single-Visit-Detail.vue";
import FullDialog from "@/view/components/FullDialog2.vue";
import Dialog from "@/view/components/Dialog.vue";
import { toSafeInteger } from "lodash";
import APIService from "@/core/services/api.service";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "Calendar",
  title: "Calendar",
  mixins: [CommonMixin],
  data() {
    return {
      selectedStatus: null,
      status: null,
      calendarDialog: true,
      toggleVisit: false,
      cWeekdays: [1, 2, 3, 4, 5, 6, 0],
      filterType: "All visit",
      calendarFilterType: "Assigned Visit",
      showCalendarFilter: false,
      pageTitle: "Calender",
      search: null,
      pageLoading: false,
      moreData: [],
      viewVisitDialog: false,
      viewDeliveryDialog: false,
      viewContractDialog: false,
      viewQuotationDialog: false,
      viewLeaveDialog: false,
      datePicker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selected: false,
      eventPopover: false,
      positionX: 0,
      positionY: 0,
      eventspopup: null,

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      moreElement: null,
      moreOpen: false,
      viewProjectDialog: false,
      visitId: 0,
      deliveryId: 0,
      contractId: 0,
      projectId: 0,
      quotationId: 0,
      leaveId: 0,
      events: [],
      settingsData: [],
      visitArr: [],
      colors: [
        "red lighten-1",
        "green lighten-1",
        "blue lighten-1",
        "orange lighten-1",
        "pink lighten-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      arrayEvents: null,
      filter: null,
      job_type: null,
      customer: 0,
      engineer: 0,
      statusList: [],
      customerList: [],
      engineerList: [],
      typeList: [
        {
          text: "One Off Job",
          value: "ono_off",
          color: "red lighten-1",
        },
        {
          text: "Recurring Job",
          value: "recurring",
          color: "green lighten-1",
        },
      ],
      filterList: [
        /*  {
          text: "Quotation",
          value: "quotation",
          color: "blue lighten-1",
        }, */
        {
          text: "Contract",
          value: "contract",
          color: "orange lighten-1",
        },
        {
          text: "Project",
          value: "project",
          color: "blue lighten-1",
        },
        {
          text: "Delivery",
          value: "delivery",
          color: "purple lighten-1",
        },
        {
          text: "Visit",
          value: "visit",
          color: "#73d7d2",
        },
        /*   {
          text: "Leave",
          value: "leave",
          color: "red lighten-1",
        }, */
      ],
      googleEventDialog: false,
      googleEventLoading: false,
      googleEventMenu: {},
      googleEventForm: null,
      googleEventRules: {
        required: (v) => !!v || "Field is required",
        max255: (v) => !v || v.length <= 255 || "Max 255 characters allowed",
        max1000: (v) => !v || v.length <= 1000 || "Max 1000 characters allowed",
        emailList: (emails) =>
          emails.every((email) => /\S+@\S+\.\S+/.test(email)) ||
          "Invalid email format",
      },
      googleEvents: [],
      googleEvent: {
        title: null,
        location: null,
        description: null,
        start_time: null,
        end_time: null,
        attendees: null,
      },
    };
  },
  methods: {
    handleGoogleEvent() {
      const validateStatus = this.$refs.googleEventForm.validate();
      if (validateStatus) {
        this.googleEventLoading = true;
        this.$store
          .dispatch(POST, {
            url: "oauth/google/events",
            data: this.googleEvent,
          })
          .then(() => {
            this.googleEventDialog = false;
            this.getGoogleCalendarEvents();
          })
          .catch(() => {
            this.googleEventLoading = false;
          })
          .finally(() => {
            this.googleEventLoading = false;
          });
      }
    },
    getGoogleCalendarEvents() {
      this.$store
        .dispatch(GET, { url: "oauth/google/events" })
        .then(({ data }) => {
          this.googleEvents = data;
          this.events = this.formatGoogleEvents(this.events, this.googleEvents);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    formatGoogleEvents(events, googleEvents) {
      const merged = [...events, ...googleEvents];
      const uniqueEvents = new Map();
      merged.forEach((event) => {
        const key = `${event.type}-${event.id}`;
        uniqueEvents.set(key, event);
      });

      return Array.from(uniqueEvents.values());
    },
    createGoogleEvent(param) {
      const startOfDay = moment(param.date)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const endOfDay = moment(param.date)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.googleEvent = {
        title: null,
        location: null,
        description: null,
        start_time: startOfDay,
        end_time: endOfDay,
        attendees: [],
      };

      this.googleEventDialog = true;
    },
    calendarStyle() {
      this.$nextTick(() => {
        const elem = document.querySelectorAll(".day-0,.day-6");
        elem.forEach((el) => {
          if (!el.parentElement.classList.contains("v-outside")) {
            el.parentElement.style.background = "#f1f1f1";
            // el.parentElement.style.background = "rgb(214 217 219)"
          }
        });
      });
    },

    lightenBackgroundColor(index) {
      switch (index) {
        case 0:
          return "#ADD8E6";
        case 1:
          return "#FFFACD";
        case 2:
          return "#FFDAB9";
        case 3:
          return "#90EE90";
        case 4:
          return "#FFCCCB";
        case 5:
          return "#FFDAB9";
        default:
          return "";
      }
    },

    handleStatusClick(row) {
      this.selectedStatus = row;
      this.getCalendar("", "", row.status);
    },

    formatDayCell(param) {
      console.log(param);
      //return {background:'red'}
    },
    setMenuPosition(e) {
      e.preventDefault();
      this.positionX = e.clientX + 50;
      this.positionY = e.clientY;
      this.$nextTick(() => {
        this.eventPopover = true;
      });
    },
    getEvents(eventData) {
      let settingData = this.lodash.find(
        this.settingsData,
        (row) => row.type.toLowerCase() == eventData.type.toLowerCase()
      );
      if (settingData && settingData.value) {
        let stringArr = [];
        this.lodash.forEach(settingData.value, (row) => {
          if (eventData && eventData[row]) {
            stringArr.push(eventData[row]);
          }
        });
        return stringArr.join("/");
      }
    },
    initFilter() {
      APIService.query("resource-calendar/filters")
        .then(({ data }) => {
          this.customerList = data.customers;
          this.statusList = data.statuses;
          this.statusList.shift(1);
          this.engineerList = data.engineers;
          /*this.statusList = data.statuses; */
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    openSingledata(data) {
      if (data.eventParsed.input && data.eventParsed.input.id) {
        if (data?.eventParsed?.input?.is_event) {
          return false;
        }
        if (data.eventParsed.input.type == "visit") {
          this.visitId = toSafeInteger(data.eventParsed.input.id);
          this.viewVisitDialog = true;
        } else if (data.eventParsed.input.type == "contract") {
          this.contractId = toSafeInteger(data.eventParsed.input.id);
          this.viewContractDialog = true;
        } else if (data.eventParsed.input.type == "quotation") {
          this.quotationId = toSafeInteger(data.eventParsed.input.id);
          this.viewQuotationDialog = true;
        } else if (data.eventParsed.input.type == "Leave") {
          this.leaveId = toSafeInteger(data.eventParsed.input.id);
          this.viewLeaveDialog = true;
        } else if (data.eventParsed.input.type == "delivery") {
          this.deliveryId = toSafeInteger(data.eventParsed.input.id);
          this.viewDeliveryDialog = true;
        } else if (data.eventParsed.input.type == "project") {
          this.projectId = toSafeInteger(data.eventParsed.input.id);
          this.viewProjectDialog = true;
        }
      }
    },
    openSingledataDoublePopup(data) {
      if (data && data.id) {
        if (data.type == "visit") {
          this.visitId = toSafeInteger(data.id);
          this.viewVisitDialog = true;
        } else if (data.type == "contract") {
          this.contractId = toSafeInteger(data.id);
          this.viewContractDialog = true;
        } else if (data.type == "quotation") {
          this.quotationId = toSafeInteger(data.id);
          this.viewQuotationDialog = true;
        } else if (data.type == "Leave") {
          this.leaveId = toSafeInteger(data.id);
          this.viewLeaveDialog = true;
        } else if (data.type == "delivery") {
          this.deliveryId = toSafeInteger(data.id);
          this.viewDeliveryDialog = true;
        } else if (data.type == "project") {
          this.projectId = toSafeInteger(data.id);
          this.viewProjectDialog = true;
        }
      }
    },
    getColor(data) {
      if (data == 1) {
        return "blue";
      } else if (data == 2) {
        return "purple";
      } else if (data == 3) {
        return "red";
      } else if (data == 4) {
        return "green";
      } else if (data == 5) {
        return "red";
      } else if (data == 6) {
        return "cyan";
      }
    },
    getPriorityColor(data) {
      if (data == "Low") {
        return "5px solid green !important";
      } else if (data == "Medium") {
        return "5px solid orange !important";
      } else if (data == "High") {
        return "5px solid red !important";
      }
    },
    getBgColor(data) {
      if (data == 1) {
        return "#E3F2FD  !important";
      } else if (data == 2) {
        return "#f9f9ce !important";
      } else if (data == 3) {
        return "#f3e6cb !important";
      } else if (data == 4) {
        return "#E8F5E9 !important";
      } else if (data == 5) {
        return "#FFEBEE !important;";
      } else if (data == 6) {
        return "#d9fffd !important";
      }
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
      this.calendarStyle();
    },
    next() {
      this.$refs.calendar.next();
      this.calendarStyle();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    /*  visitFuntion(){
      alert(43533)
    }, */
    showAllEvent({ nativeEvent, event }) {
      this.selectedElement = nativeEvent.target;
      const date = this.selectedElement.getAttribute("data-date");
      const open = () => {
        // this.selectedEvent = event;
        console.log("event", event);
        this.moreElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.moreOpen = true))
        );
      };

      if (this.moreOpen) {
        this.moreOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
      this.getPopupData(date);
      this.getPopupVisit(date);
    },
    getPopupVisit(date) {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "popup-calendar",
          data: { start_date: date },
        })
        .then((data) => {
          _this.eventspopup = data;
        });
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      /* this.events = events; */
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getVisitList(datePicker, type) {
      const _this = this;
      _this.pageLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit-list",
              data: {
                date: datePicker,
                type: type,
                search: this.search,
              },
            })
            .then(({ data }) => {
              this.visitArr = data;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.pageLoading = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCalendar(date, visit, status) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "all/calendar",
          data: {
            date: date ? date : null,
            visit_id: visit ? visit : null,
            filter: this.filter,
            job_type: this.job_type ? this.job_type : null,
            customer: this.customer ? this.customer : null,
            engineer: this.engineer ? this.engineer : null,
            status: status ? status : null,
          },
        })
        .then(({ data }) => {
          _this.events = _this.formatGoogleEvents(data.row, _this.googleEvents);
          _this.settingsData = data.settings;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getPopupData(date) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "all/calendar",
          data: {
            date: date,
          },
        })
        .then((data) => {
          _this.moreData = data.data.row;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      if ([12, 17, 28].includes(parseInt(day, 10))) return true;
      if ([1, 19, 22].includes(parseInt(day, 10))) return ["red", "#00f"];
      return false;
    },
    genrateEvent() {
      const event = [...Array(36)].map(() => {
        const day = Math.floor(Math.random() * 30);
        const d = new Date();
        d.setDate(day);
        return d.toISOString().substr(0, 10);
      });
      return event;
    },
  },
  components: {
    FullDialog,
    CreateEvent,
    DatePicker,
    Dialog,
    SingleVisitDetail,
    SingleQuotationDetail,
    SingleLeaveDetail,
    SingleContractDetail,
    SingleDeliveryDetail,
    SingleProjectDetail,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  mounted() {
    this.arrayEvents = this.genrateEvent();
    this.getCalendar();
    this.getVisitList();
    this.initFilter();
    this.$nextTick(() => {
      this.calendarStyle();
      this.getGoogleCalendarEvents();
    });
  },
};
</script>
<style scoped>
.w-100 {
  width: 100px;
}
.all-event-wrapper th,
.all-event-wrapper td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}
.all-event-wrapper th {
  font-weight: 500;
}
</style>
